<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex jutify-space-between col-sm-2 py-4">
        <v-card v-if="tableSwitch === 'banner'" class="d-flex align-center justify-center px-4 fill-width primary caption font-weight-bold py-2" dark outlined @click.prevent="MEDIA_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Banner
        </v-card>
        <v-card v-else class="d-flex align-center justify-center px-4 fill-width primary caption font-weight-bold py-2" dark outlined @click.prevent="MEDIA_SHOW()">
          <v-icon small class="mr-2">
            mdi-plus
          </v-icon>
          New Media
        </v-card>
      </v-col>
      <v-col cols="12" class="col-sm-6 white">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-text-field
            v-model="tableSearch"
            outlined
            dense
            hide-details
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="col-sm-4">
        <div class="px-4 pt-4 pb-0 pb-sm-4">
          <v-select
            v-model="tableSwitch"
            :items="[{ name: 'ARTICLE', value: 'article' }, { name: 'BANNER', value: 'banner' }]"
            item-value="value"
            item-text="name"
            outlined
            dense
            hide-details
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-data-table
          dense
          :headers="tableSwitch === 'banner' ? headers : headersFS"
          :items="table"
          item-key="id"
          :server-items-length="tableTotal"
          :page.sync="tablePage"
          :items-per-page.sync="tableLimit"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100]
          }"
          :loading="tableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.title`]="{ item }">
            <div class="py-2">
              <div>
                {{ item.title }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div class="py-2">
              <div>
                {{ $localDT(item.created, 'display') }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div>
              {{ item.description }}
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div>
              {{ !parseInt(item.status) ? 'Tidak Aktif' : 'Aktif' }}
            </div>
          </template>
          <template v-slot:[`item.option`]="{ item }">
            <div style="min-width: 100px;">
              <v-btn v-if="$role(['manager'], $store.state.user.role)" depressed small dark color="orange" class="rounded-0 text-capitalize" @click.prevent="MEDIA_SHOW(item)">
                <v-icon small class="mr-2">
                  mdi-information-outline
                </v-icon>
                Detail
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogMedia.show"
      persistent
      scrollable
      max-width="340"
    >
      <v-card v-if="dialogMedia.data" >
        <v-card-title class="body-1 justify-center">
          {{ dialogMedia.data.id ? 'Update Media' : 'Create New Media' }}
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2" style="max-height: 75vh;">
          <v-select
            v-model="dialogMedia.data.type"
            :items="[
              { name: 'Article', value: 'article' },
              { name: 'Banner', value: 'banner' }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Media Type"
          />
          <div style="position: relative;" @click.prevent="UploadImage()">
            <v-img
              :src="dialogMedia.data.images"
              :aspect-ratio="dialogMedia.data.type === 'banner' ? 2 : 1"
              class="blue lighten-4 fill-width mb-4"
            />
            <div class="d-flex fill-width fill-height align-center justify-center caption c-pointer text-center" style="position: absolute;top: 0px;left:0px;">
              <span v-if="loadingImageUpload">
                uploading ...
              </span>
              <span v-else>
                {{ dialogMedia.data.images ? '+ Change Image' : '+ Add Image' }}<br>
                {{ dialogMedia.data.type === 'banner' ? '1:1' : '1:2' }}
              </span>
            </div>
          </div>
          <v-text-field
            v-model="dialogMedia.data.title"
            outlined
            dense
            hide-details
            label="Title"
            class="mb-4 rounded-0"
          />
          <v-textarea
            v-if="dialogMedia.data.type === 'article'"
            v-model="dialogMedia.data.content"
            outlined
            dense
            rows="6"
            hide-details
            label="Content"
            class="mb-4 rounded-0"
          />
          <v-select
            v-model.number="dialogMedia.data.status"
            :items="[
              { name: 'Aktif', value: 1 },
              { name: 'Tidak Aktif', value: 0 }
            ]"
            item-text="name"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-4 rounded-0"
            label="Publish Status"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            class="text-capitalize"
            @click="dialogMedia.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="dialogMedia.data && parseInt(dialogMedia.data.id)"
            color="red darken-1"
            text
            class="text-capitalize mr-4"
            @click="MEDIA_PROCESS(true)"
          >
            Remove
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            class="text-capitalize"
            @click="MEDIA_PROCESS()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
let searchInterval = null
export default {
  name: 'Feature',
  data: () => ({
    tableSwitch: 'article',
    headersFS: [
      { text: 'Title', value: 'title', sortable: false },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    headers: [
      { text: 'Title', value: 'title', sortable: false },
      { text: 'Created', value: 'created', sortable: false },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Opsi', value: 'option', sortable: false }
    ],
    tableSearch: '',
    tableLoading: false,
    tableAchievement: 3,
    table: [],
    tableTotal: 0,
    tablePage: 1,
    tableLimit: 25,
    tableSearchStatus: null,
    options: {},
    dialogMedia: {
      show: false,
      data: null
    },
    dialogBanner: {
      show: false,
      data: null
    },
    loadingImageUpload: false
  }),
  computed: {
    ...mapState({
      storeActive: state => state.storeActive
    })
  },
  watch: {
    tableSwitch (v) {
      this.MEDIA_GET(this.tableSearch)
    },
    tableSearch: function (q) {
      if (searchInterval) {
        clearTimeout(searchInterval)
      }
      searchInterval = setTimeout(() => {
        this.MEDIA_GET(q)
      }, 300)
    },
    tableSearchStatus () {
      this.MEDIA_GET(this.tableSearch)
    }
  },
  mounted () {
    this.MEDIA_GET()
  },
  methods: {
    MEDIA_SHOW (media) {
      if (media) {
        this.dialogMedia.data = Object.assign({}, media)
      } else {
        this.dialogMedia.data = {
          id: null,
          type: this.tableSwitch,
          title: '',
          content: '',
          status: 1
        }
      }
      this.dialogMedia.show = true
    },
    MEDIA_GET (q) {
      const status = this.tableSearchStatus === 0 || this.tableSearchStatus > 0 ? this.tableSearchStatus : ''
      this.tableLoading = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const query = '?c=' + (parseInt(page) > 0 ? '&page=' + page : '') + (parseInt(itemsPerPage) > 0 ? '&limit=' + parseInt(itemsPerPage) : '') + (sortBy.length ? '&sortby=' + sortBy[0] + (sortDesc[0] ? '&sort=DESC' : '&sort=ASC') : '')
      this.$store.dispatch('MEDIA_GET', query + (q ? ('&q=' + q) : '') + ('&type=' + (this.tableSwitch || 'media')) + (parseInt(status) === 0 || parseInt(status) > 0 ? '&status=' + status : '')).then((res) => {
        if (res.status) {
          this.table = res.data.data
          this.tableTotal = res.data.data.length
        } else {
          this.table = []
          this.tableTotal = 0
        }
        this.tableLoading = false
      })
    },
    MEDIA_PROCESS (isRemove) {
      const processedMedia = Object.assign({}, this.dialogMedia.data)
      if (isRemove) {
        if (!confirm('Remove this media?')) {
          return false
        }
        processedMedia.status = 100
      } else {
        if (!processedMedia.title) {
          this.$store.dispatch('TOAST', { show: true, message: 'Masukan judul yang valid!' })
          return false
        }
        if (!processedMedia.content && processedMedia.type === 'article') {
          this.$store.dispatch('TOAST', { show: true, message: 'Masukan konten yang valid!' })
          return false
        }
      }
      this.$store.dispatch('MEDIA_PROCESS', processedMedia)
        .then((res) => {
          if (isRemove) {
            this.MEDIA_GET(this.tableSearch)
            this.dialogMedia.show = false
          }
          if (res.status) {
            this.MEDIA_GET(this.tableSearch)
            this.dialogMedia.show = false
            this.$store.dispatch('TOAST', { show: true, message: processedMedia.id ? 'Berhasil diupdate' : 'Berhasil dibuat' })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
          }
        })
    },
    UploadImage () {
      if (!this.loadingImageUpload) {
        let u = document.getElementById('file-uploader')
        if (u) {
          u.remove()
        }
        setTimeout(() => {
          u = document.createElement('input')
          u.setAttribute('type', 'file')
          u.setAttribute('id', 'file-uploader')
          u.setAttribute('class', 'file-uploader')
          u.setAttribute('accept', '.png,.gif,.jpeg,.jpg')
          u.addEventListener('change', (e) => {
            this.uploadPhotoRender(e)
          })
          document.body.appendChild(u)
          u.click()
        }, 100)
      }
    },
    async uploadPhotoRender (event) {
      /* eslint-disable */
      const $ = this
      await $.$filetoBase64(event, async function (res) {
        if (res.status) {
          $.loadingImageUpload = true
          await $.$store.dispatch('UPLOAD_FILE', {
            file: res.data,
            source: $.tableSwitch === 'banner' ? 'banner' : 'media'
          }).then((resUpload) => {
            if (resUpload.status) {
              if (resUpload.data.data.res) {
                $.dialogMedia.data.images = resUpload.data.data.secure_url
              } else {
                $.$store.dispatch('TOAST', { show: true, message: 'Gagal upload, silahkan coba lagi!' })
              }
            }
            $.loadingImageUpload = false
          })
        } else {
          res.data === 'file_size'
            ? $.$store.dispatch('TOAST', { show: true, message: 'Image size no more than 1Mb' }) // ? $.$store.commit('TOAST', { show: true, message: 'Ops, file image is too large. Max size (files < 5Mb & video < 20Mb)' })
            : res.data === 'canceled'
              ? console.log('Upload canceled.')
              : console.log(res.data)
        }
      })
    }
  }
}
</script>
